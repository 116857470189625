"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _formik = require("formik");
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _lodash2 = _interopRequireDefault(require("lodash.pick"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _useEmbedEvents = require("../hooks/useEmbedEvents");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const SearchFiltersModal = ({
  onClose,
  open,
  filtersActive,
  removeActiveFilters,
  dirtyCheckKeys: filterKeys,
  children
}) => {
  const {
    submitForm,
    values,
    initialValues
  } = (0, _formik.useFormikContext)();
  const formFiltersActive = (0, _react.useMemo)(() => !(0, _lodash.default)((0, _lodash2.default)(initialValues, filterKeys), (0, _lodash2.default)(values, filterKeys)), [filterKeys, initialValues, values]);
  const sendEvent = (0, _useEmbedEvents.useSendEvent)();
  (0, _react.useEffect)(() => {
    sendEvent({
      type: 'iframeFullScreenToggle',
      fullscreen: open,
      source: 'travelbase'
    });
  }, [open, sendEvent]);
  return __jsx(StyledModal, {
    onClose: onClose,
    open: open
  }, __jsx(_ModalHeader.default, {
    style: {
      textTransform: 'capitalize'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "JwI+G2",
    defaultMessage: [{
      "type": 0,
      "value": "filters"
    }]
  })), __jsx(_Stack.default, {
    mt: 4,
    spacing: 4
  }, children, __jsx(Actions, null, __jsx(_Button.default, {
    style: {
      width: '100%'
    },
    onClick: () => {
      onClose();
      // As the modal is rendered through a portal, the form won't be automatically submitted
      // so we need to do it manually.
      submitForm();
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "u11JXQ",
    defaultMessage: [{
      "type": 0,
      "value": "Filters toepassen"
    }]
  })), __jsx(_Button.default, {
    variant: "outline",
    disabled: !formFiltersActive && !filtersActive,
    onClick: () => {
      removeActiveFilters();
    },
    style: {
      width: '100%'
    },
    type: "button"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "+PgRNi",
    defaultMessage: [{
      "type": 0,
      "value": "Filters verwijderen"
    }]
  })))));
};
var _default = exports.default = SearchFiltersModal;
const StyledModal = (0, _styledComponents.default)(_Modal.default).withConfig({
  displayName: "SearchFiltersModal__StyledModal",
  componentId: "o3gk2r-0"
})(["> [data-reach-dialog-content]{height:auto;}"]);
const Actions = _styledComponents.default.div.withConfig({
  displayName: "SearchFiltersModal__Actions",
  componentId: "o3gk2r-1"
})(["position:sticky;bottom:0;left:0;button + button{margin-top:", ";}width:100%;"], ({
  theme
}) => theme.spacing['30_Small']);